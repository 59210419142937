.table {
	[data-bs-toggle="collapse"] {
		td > .fa.fa-chevron-right {
			transform: rotate(0);
			transition: transform .25s;
		}
		&[aria-expanded="true"] td > .fa.fa-chevron-right {
			transform: rotate(90deg);
		}
	}

	&.table-nowrap {
		th, td {
			white-space: nowrap;
		}
	}
}