.navbar {
    min-height: 80px;
}
.navbar-nav {
    position: relative;
    align-items: center;

    @include media-breakpoint-down(md) {
        align-items: start;
    }

    li {
        padding-right: 4px;
    }
    .v-divider {
        margin-left: 5px;
        margin-right: 5px;
        width: 1px;
        height: 1.5rem;
        border-left: 1px solid $lightgray;

        &:nth-child(1) {
            display: none;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }
    }    
}
