// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';

@import 'custom/nav';
@import 'custom/form';
@import 'custom/table';
@import 'custom/modal';
@import 'flatpickr/dist/flatpickr';

@import 'devextreme/dist/css/dx.light'