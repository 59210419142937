.shadow-sm{box-shadow:none!important;}
.modal-dialog{z-index:9999;}
.helpModal .modal-content, #thankyou .modal-content{
    background-image: url('../../../public/img/thank-you-bg.jpg');
    background-size: cover;
}
.thank-you-pop{
    background-color: #ffffffdd;
}
.thank-you-divider {
    position:relative;
    top: -9px;
}
#thankyou{margin-top: 12vh;}