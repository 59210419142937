.required::after {
    content: " *";
    color: red;
}
.form-control{transition: all 2.5s ease-in-out;}
.changed{transition: all 2.5s ease-in-out; background-color:#fffadb!important;}
.controls{
	display:flex;
	flex-direction: row-reverse;
	pointer-events: none;
	margin-top:-58px;
	margin-right:2px;
	margin-bottom:var(--bs-gutter-y);
	min-height:34px;
}
.clickable-checkmark,
.clickable-closemark {
    cursor: pointer;
    margin-left: 5px; /* Adjust as needed for spacing */
    font-size: 1em;
	pointer-events: auto;
}
.clickable-checkmark .fas, .clickable-closemark .fas {
	transition: all 2.5s ease-in-out;
	background-color:#fff;
	border-radius: 360px;
}
.clickable-checkmark {
	color: green;
	position: relative;
	left:2px;
}
.clickable-closemark {
	color: red;
}
.change-controls {
	position: relative;
	margin-bottom:var(--bs-gutter-y);
}
.form-control.change {
  transition: all 2.5s ease-in-out;
  border-color: green;
	background-color:#e5eee5!important;
}

.form-control.deny {
  transition: all 2.5s ease-in-out;
  border-color: red;
	background-color:#f5dede!important;
}